import "./App.css";
import "@fontsource/space-grotesk";
import { useState } from "react";
import { LanguageContext } from "./shared/context/lan-context";
import Router from "./components/Router";
import ChatIcon from "./chat.png";

function App() {
  const [language, setLanguage] = useState(localStorage.getItem("lan") || "tm");

  // const [chat, setChat] = useState(false);
  console.log = function () {};
  const createLanguage = (lan) => {
    setLanguage(lan);
  };

  return (
    <div className="select-none">
      <LanguageContext.Provider
        value={{
          language: language,
          createLanguage: createLanguage,
          setLanguage: (value) => {
            setLanguage(value);
          },
        }}
      >
        <Router>
          <Router />
        </Router>
      </LanguageContext.Provider>
      {/* <div className="fixed right-0 bottom-0 md:m-[30px] m-0 z-50"> */}
      {/* <div className={` ${!chat && "hidden"} relative`}>
          <div className="w-full h-32  absolute right-0 left-0 z-100 bg-[#2b3bd4] flex justify-start items-center ">
            <h1 className="text-white text-2xl ml-12 w-max">Oguzbot</h1>
          </div>
          <iframe
            className="border-[1px] rounded-[10px] border-solid z-10 mb-[70px] "
            src="https://console.dialogflow.com/api-client/demo/embedded/a122e89f-b53b-4bc2-8688-0b35f16b40b5"
            width={400}
            height={500}
            title="Iframe Example"
          ></iframe>
        </div> */}
      {/* <div
          onClick={() => {
            setChat(!chat);
          }}
          className="w-[60px] h-[60px] fixed right-0 bottom-0 md:m-[30px] m-0 "
        >
          <img src={ChatIcon} alt="" />
        </div> */}
      {/* </div> */}
    </div>
  );
}

export default App;
