import React, { useEffect, useState } from "react";
import Loading from "./loading.gif";

const PageLoading = () => {
  useEffect(() => {
    localStorage.setItem("loading_image", Loading);
  }, []);
  return (
    <div className="w-[100vw] h-[100vh] bg-white flex justify-center items-center">
      <img
        src={localStorage.getItem("loading_image") || Loading}
        alt="loading-gif"
        className="w-[15vw]"
      />
    </div>
  );
};

export default PageLoading;
