import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translations: require("./locales/en/translations.json"),
  },
  ja: {
    translations: require("./locales/ja/translations.json"),
  },
  ru: {
    translations: require("./locales/ru/translations.json"),
  },
  tm: {
    translations: require("./locales/tm/translations.json"),
  },
};

let lan = JSON.parse(localStorage.getItem("data"));
if (lan) {
  lan = lan.lan;
} else {
  lan = "tm";
}

i18n.use(initReactI18next).init({
  fallbackLng: "tm",
  resources,
  lng: lan,

  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = ["en", "ja", "ru", "tm"];

export default i18n;
