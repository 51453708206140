import { React, lazy, Suspense } from "react";
import { Navigate, useRoutes } from "react-router-dom";
// import Loading from "./Loading/Loading";
import PageLoading from "./Loading/PageLoading";
import Olympiad from "../pages/Olympiad";

const Home = lazy(() => import("../pages/Home"));
const News = lazy(() => import("../pages/News"));
const Gorelde = lazy(() => import("../pages/Gorelde"));
const Talyp = lazy(() => import("../pages/Talyp"));
const Graduates = lazy(() => import("../pages/Graduates"));
const Faculties = lazy(() => import("../pages/Faculties"));
const ProductCenter = lazy(() => import("../pages/ProductCenter"));
const Major = lazy(() => import("../pages/Major"));
const AboutUs = lazy(() => import("../pages/AboutUs"));
const Frame = lazy(() => import("../pages/Frame"));
const RearchPublic = lazy(() => import("../pages/RearchPublic"));
const CursCenter = lazy(() => import("../pages/CursCenter"));
const NewsOpen = lazy(() => import("../pages/NewsOpen"));
const ResearchOpen = lazy(() => import("../pages/ResearchOpen"));
const MajorView = lazy(() => import("../pages/MajorView"));
const ProductOpen = lazy(() => import("../pages/ProductOpen"));

const SideBarNavbar = lazy(() => import("./SidebarNavbar"));

function Router() {
  let routes = useRoutes([
    {
      element: (
        <Suspense fallback={<PageLoading />}>
          <SideBarNavbar />
        </Suspense>
      ),
      children: [
        {
          element: (
            <Suspense fallback={<PageLoading />}>
              <Home />
            </Suspense>
          ),
          path: "/",
        },

        {
          element: (
            <Suspense fallback={<PageLoading />}>
              <News />
            </Suspense>
          ),
          path: "/news",
        },
        {
          element: (
            <Suspense fallback={<PageLoading />}>
              <Olympiad />
            </Suspense>
          ),
          path: "/olympiad",
        },
        {
          element: (
            <Suspense fallback={<PageLoading />}>
              <NewsOpen />
            </Suspense>
          ),
          path: "/news/:newsId",
        },
        {
          element: (
            <Suspense fallback={<PageLoading />}>
              <Gorelde />
            </Suspense>
          ),
          path: "/gorelde",
        },
        {
          element: (
            <Suspense fallback={<PageLoading />}>
              <Talyp />
            </Suspense>
          ),
          path: "/talyp",
        },
        {
          element: (
            <Suspense fallback={<PageLoading />}>
              <Graduates />
            </Suspense>
          ),
          path: "/graduates",
        },
        {
          element: (
            <Suspense fallback={<PageLoading />}>
              <Faculties />
            </Suspense>
          ),
          path: "/faculty",
        },
        {
          element: (
            <Suspense fallback={<PageLoading />}>
              <Major />
            </Suspense>
          ),
          path: "/major/:facultyId",
        },
        {
          element: (
            <Suspense fallback={<PageLoading />}>
              <ProductOpen />
            </Suspense>
          ),
          path: "/productOpen/:productId",
        },
        {
          element: (
            <Suspense fallback={<PageLoading />}>
              <AboutUs />
            </Suspense>
          ),
          path: "/about-us",
        },
        {
          element: (
            <Suspense fallback={<PageLoading />}>
              <ProductCenter />
            </Suspense>
          ),
          path: "/product-center",
        },
        {
          element: (
            <Suspense fallback={<PageLoading />}>
              <Frame />
            </Suspense>
          ),
          path: "/frame",
        },
        {
          element: (
            <Suspense fallback={<PageLoading />}>
              <RearchPublic />
            </Suspense>
          ),
          path: "/Rearch_Public",
        },
        {
          element: (
            <Suspense fallback={<PageLoading />}>
              <ResearchOpen />
            </Suspense>
          ),
          path: "/research/:researchId",
        },
        {
          element: (
            <Suspense fallback={<PageLoading />}>
              <CursCenter />
            </Suspense>
          ),
          path: "/curs-center",
        },
        {
          element: (
            <Suspense fallback={<PageLoading />}>
              <MajorView />
            </Suspense>
          ),
          path: "/major_view/:majorID",
        },
      ],
    },
    {
      element: <Navigate to="/" />,
      path: "*",
    },
  ]);
  return routes;
}

export default Router;
